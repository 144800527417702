<template>
  <section class="pa-5">
    <p class="text-h6 secondary--text">Contratos asignados</p>
    <!--Filtros-->
    <v-row>
      <v-col cols="12" sm="12" md="4">
        <v-text-field
          label="Código"
          outlined
          hide-details
          clearable
          @keyup="filternumero"
          @click:clear="getContratos()"
          v-model="filters.numero"
        />
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-menu
          ref="menuDataRange"
          v-model="menuFecha"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              v-model="dateFormatted"
              label="Fecha adjudicación"
              prepend-inner-icon="mdi-calendar"
              readonly
              hide-details
              v-bind="attrs"
              v-on="on"
              clearable
              @click:clear="(filters.fecha_adjudicacion = null), getContratos()"
            />
          </template>
          <v-date-picker
            title="es"
            v-model="filters.fecha_adjudicacion"
            locale="es-Es"
            @change="getContratos()"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-select
          label="Forma contratación"
          :items="ctlModalidades"
          item-value="id"
          item-text="nombre"
          outlined
          clearable
          hide-details
          v-model="filters.id_forma_contratacion"
          @change="getContratos()"
        />
      </v-col>
    </v-row>
    <data-table-component
      v-models:select="perPage"
      v-models:pagina="page"
      :headers="headers"
      :items="items"
      @paginar="paginate"
      :total_registros="totalRows"
    >
      <template v-slot:item.proceso_compra="{ item }">
        <span>
          <b>{{ item.codigo_proceso }} - </b>
          {{  item.nombre_proceso  }}
        </span>
      </template>
      <template v-slot:[`item.fecha_hora_contratacion`]="{ item }">
        {{ moment(item.fecha_hora_contratacion).format("DD/MM/YYYY") }}
      </template>
      <template #[`item.monto_adjudicado`]="{ item }">
        {{
          Intl.NumberFormat("en-US", {
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 8,
          }).format(item.monto_adjudicado)
        }}
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click="goToSeguimiento(item)"
              v-bind="attrs"
              v-on="on"
              color="secondary"
            >
              mdi-eye
            </v-icon>
          </template>
          <span> {{ item.id_estado_contrato === 2 ? 'Ver seguimiento' : 'Ver liquidación' }}</span>
        </v-tooltip>
      </template>
    </data-table-component>
  </section>
</template>
<script>
import DataTableComponent from "@/components/DataTableComponent.vue";

import moment from "moment";
import Sortable from 'sortablejs';
export default {
  name: "ProcesosAsignadosView",
  components: {
    DataTableComponent,
  },
  data: () => ({
    filters: {
      id_forma_contratacion: null,
      fecha_adjudicacion: null,
      numero: null,
    },
    menuFecha: false,
    headers: [
      {
        text: "Código",
        value: "numero",
        align: "center",
      },
      {
        text: "Proceso de compra",
        value: "proceso_compra",
      },
      {
        text: "Nombre contrato",
        value: "nombre_contrato",
      },
      {
        text: "Fecha contratación",
        value: "fecha_hora_contratacion",
      },
      {
        text: "Monto adjudicado ($)",
        value: "monto_adjudicado",
        align: "center",
      },
      {
        text: "Plazo contractual",
        value: "plazo_contractual",
        align: "center",
      },
      {
        text: "Proveedor",
        value: "nombre_proveedor",
      },
      {
        text: "Institución",
        value: "nombre_institucion",
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable:false
      },
    ],
    items: [],
    totalRows: 0,
    page: 1,
    perPage: 10,
    ctlModalidades: [],
    timer: null,
  }),
  computed: {
    dateFormatted() {
      return this.filters.fecha_adjudicacion
        ? moment(this.filters.fecha_adjudicacion).format("DD/MM/YYYY")
        : "";
    },
  },
  methods: {
    paginate(params) {
      const { cantidad_por_pagina, pagina } = params;
      this.perPage = cantidad_por_pagina;
      this.page = pagina;
      this.getContratos();
    },
    filternumero() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(async () => {
        await this.getContratos();
      }, 500);
    },

    async getContratos() {


      const { status, data, headers } =
        await this.services.ContratoService.getProcesosAdminContratos({
          ...this.filters,
          page: this.page,
          per_page: this.perPage,
          pagination: true
        })
      if (status == 200) {
        this.items = data;
        this.totalRows = Number(headers.total_rows);
      }

    },
    async fetchModalidades() {
      const response = await this.services.Paac.getModalidades({
        compra_tradicional: true,
      });
      if (response) {
        this.ctlModalidades = response.data.forma_contratacion;
      }
    },
    goToSeguimiento(item) {
      if(item.id_estado_contrato === 2){
        this.$router.push({
          name: "seguimiento-orden-compra",
          params: { idContrato: item.id_contrato },
          query: { idProveedor: item.id_referencia },
        });
      } else {
        this.$router.push({
          name: "liquidacion-orden-compra",
          params: { idContrato: item.id_contrato }
        })
      }
    },
  },
  created() {
    this.getContratos();
    this.fetchModalidades();
  },
};
</script>
